import React from 'react'
import PropTypes from 'prop-types'

import './style.css'
import {Menu} from "../Menu/Menu";

const Layout = ({children}) => {
  return (
    <>
      <Menu/>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <main style={{border: '1px dashed gray'}}>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
